import BackToServices from "../../components/button/BackToServices";

const Man = () => {
    return (<div className="wrapper">
        <div className="content">
            <div id="single">
                <div id="single-content">
                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="/assets/img/bg/man.jpg" alt="Uomo" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                        <div className="description">
                            <p>Il servizio di taglio e trattamento dei capelli per uomo si concentra su uno stile personalizzato, studiato per esaltare la personalità e il carattere di ogni cliente, con particolare attenzione alla salute e alla cura del capello.</p>
                            <p>Dopo un'attenta consulenza, il parrucchiere propone un taglio che valorizza al meglio la forma del viso e la natura dei capelli, completato da rifiniture e modellatura della barba, eseguite con cura e precisione per un aspetto impeccabile e ben definito.</p>
                            <p>Il servizio di taglio e trattamento dei capelli per uomo si concentra su uno stile personalizzato, studiato per esaltare la personalità e il carattere di ogni cliente, con particolare attenzione alla salute e alla cura del capello e del cuoio capelluto.</p>
                            <p>Per maggiori informazioni su questi trattamenti consultare le pagine “Trattamenti cuoio capelluto” e “Trattamento Anticaduta Deepshoot”</p>
                        </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                        <div className="title">
                            <h2 className="hr">Listino prezzi</h2>
                        </div>

                        <div className="price-list">
                            <div className="price-list-items">
                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Uomo</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Rasatura</span>
                                            <span className="list-group-price">30 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio</span>
                                            <span className="list-group-price">35 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Barba
                                                <i style={{ display: 'block', fontSize: '16px', marginTop: '10px' }}>Con l'applicazione di un rilassante panno caldo</i>
                                            </span>
                                            <span className="list-group-price">da 22 a 32 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Scrub esfoliante purificante
                                            </span>
                                            <span className="list-group-price">5 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio + Barba
                                                <i style={{ display: 'block', fontSize: '16px', marginTop: '10px' }}>Con l'applicazione di un rilassante panno caldo</i>
                                            </span>
                                            <span className="list-group-price">da 50 a 60 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Colore</span>
                                            <span className="list-group-price">35 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Colore + Taglio</span>
                                            <span className="list-group-price">60 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Riflessi naturali</span>
                                            <span className="list-group-price">20 CHF</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Bambino</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio (fino a 11 anni)</span>
                                            <span className="list-group-price">15 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio (12-17 anni)</span>
                                            <span className="list-group-price">25 CHF</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="title">
                                        <h6>Sconto Studenti: 20%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BackToServices />
                </div>
            </div>
        </div>
    </div>
    )
}

export default Man;