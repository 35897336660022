import React from 'react';

const AboutOurTeam = () => {
    return (
        <div id="our-team" className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr">Il Nostro Team</h2>
            </div>

            <div className="row gutter-width-sm with-pb-md">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit not-gray">
                            <div className="object-fit-cover">
                                <img src="/assets/img/team/germano.JPEG" alt="Germano" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position text-center">
                                <p>Hair Stylist</p>
                            </div>

                            <h4 className="team-member-t-head text-center">Germano</h4>

                            <div className="team-member-description text-center">
                                <p>Germano è un professionista che da oltre 40 anni svolge la sua attività con passione e dedizione, costruendo e mantenendo un rapporto di fiducia con la sua affezionata clientela. La sua lunga esperienza lo ha reso un maestro nell'arte del taglio, sia per uomo che per donna, come nelle pieghe e nelle colorazioni. Sempre attento alle ultime tendenze e innovazioni, Germano considera l'aggiornamento continuo un elemento fondamentale del suo lavoro. Questo gli consente di combinare la sua esperienza con la creatività, offrendo ai suoi clienti un servizio di alta qualità, arricchito da costanti novità.</p>
                            </div>
                        </div>

                        {/* <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/saloneborgodoro/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit not-gray">
                            <div className="object-fit-cover">
                                <img src="/assets/img/team/chiara.JPEG" alt="Chiara" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position text-center">
                                <p>Hair Stylist</p>
                            </div>

                            <h4 className="team-member-t-head text-center">Chiara</h4>

                            <div className="team-member-description text-center">
                                <p>Chiara lavora nel settore dal 2008 e negli anni si è specializzata nei trattamenti con oli essenziali, prediligendo sempre prodotti naturali. Ama realizzare colorazioni e schiariture con tecniche delicate e rispettose della salute dei capelli. Inoltre, si dedica con passione al trattamento delle anomalie cutanee, aiutando le clienti a risolvere i loro disagi e a sentirsi più sicure.</p>
                            </div>
                        </div>

                        {/* <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/saloneborgodoro/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit not-gray">
                            <div className="object-fit-cover">
                                <img src="/assets/img/team/jessica.JPEG" alt="Jessica" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position text-center">
                                <p>Hair Stylist</p>
                            </div>

                            <h4 className="team-member-t-head text-center">Jessica</h4>

                            <div className="team-member-description text-center">
                                <p>Jessica lavora come parrucchiera dal 2010 e, nel corso degli anni, ha continuato a investire nella sua formazione per rimanere aggiornata sulle ultime tendenze del settore, partecipando a vari corsi di aggiornamento. Ha una solida esperienza in colorazioni e schiariture, oltre a competenze nel taglio per uomo e nella lavorazione della barba. Ama prendersi cura dei suoi clienti, ascoltando attentamente le loro esigenze e proponendo tagli moderni, fornendo consigli personalizzati per garantire la loro piena soddisfazione.</p>
                            </div>
                        </div>

                        {/* <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/saloneborgodoro/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutOurTeam;
