import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Telefono</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>
                            <a href="tel:0041916461351">0041 916461351</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Email</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>
                            <a href="mailto:company@domain.com">saloneborgodoro@gmail.com</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Social</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>
                            <a title="Instagram" href="https://www.instagram.com/saloneborgodoro/" target='_blank'><img style={{ width: '20px',marginRight:'3px',marginBottom:'4px' }} src='assets/icons/instagram.png' alt='instagram'></img>Instagram</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Indirizzo</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>Via Luigi Lavizzari 21, 6850 Mendrisio, Svizzera</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Contacts;
