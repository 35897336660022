import BackToServices from "../../components/button/BackToServices";

const DeepshotTreaments = () => {
    return (<div className="wrapper">
        <div className="content">
            <div id="single">
                <div id="single-content">
                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="/assets/img/bg/deepshot.jpg" alt="Trattamenti anticaduta Deepshots" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                        <div className="description">
                            <p>L'innovativo trattamento anticaduta DeepShoot® è progettato per bloccare la caduta dei capelli, riuscendo in alcuni casi a favorire la ricrescita, regolare la cute grassa caratterizzata da un'eccessiva produzione di sebo e per curare anomalie del cuoio capelluto come prurito, alopecia, dermatite, etc. La fiala DeepShoot contiene un cocktail di varie molecole biochimiche, presenti naturalmente nel nostro corpo:</p>
                            <ul>
                                <li>
                                La biotina, che promuove la produzione di cheratina e migliora la crescita dei follicoli.
                                </li>
                                <li>
                                I fattori di crescita e i recettori dell'adenosina A2, che aiutano a facilitare un recupero più rapido dal ciclo di rigenerazione.
                                </li>
                                <li>
                                La vitamina C, che migliora l'assorbimento degli ioni consentendo una crescita dei capelli più sana e forte.
                                </li>
                                <li>
                                Gli acidi ialuronici, che idratano e nutrono il cuoio capelluto.
                                </li>
                            </ul>
                            <p>Il segreto del trattamento risiede nella testina della fiala, composta da 19 microaghi che penetrano fino a 0.5 mm, permettendo ai principi attivi di raggiungere direttamente il follicolo. La dimensione ridotta degli aghi rende il trattamento completamente indolore. Il trattamento inizia con un'analisi gratuita con la microcamera, che consente di esaminare in dettaglio le condizioni dei capelli e del cuoio capelluto, identificando le cause specifiche della caduta e eventuali anomalie. Dopodichè, vi verrà proposto il percorso di trattamento DeepShoot più adatto
                            </p>
                        </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                        <div className="title">
                            <h2 className="hr">Listino prezzi</h2>
                        </div>

                        <div className="price-list">
                            <div className="price-list-items">
                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Trattamento Anticaduta DeepShoot</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Analisi Microcamera</span>
                                            <span className="list-group-price">GRATUITA</span>
                                        </li>
                                    </ul>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Trattamento Anticaduta DeepShoot</span>
                                            <span className="list-group-price">150 CHF/seduta</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BackToServices />
                </div>
            </div>
        </div>
    </div>
    )
}

export default DeepshotTreaments;