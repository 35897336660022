import React, { Fragment, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';
import ContactForm from '../components/form/ContactForm';
import GoogleMapsStyle from '../data/google-maps/googleMapsStyle.json';

// import GoogleMaps from '../blocks/google-maps/GoogleMaps';
import ContactsInside from '../blocks/contacts/Contacts';

const Contacts = () => {
    const mapRef = useRef();
    let map = null;
    useEffect(() => {
        generateMaps();
    })

    function generateMaps() {
        // 189 Bedford Ave, Brooklyn, NY 11211, United States
        var address_latlng = new window.google.maps.LatLng(45.87044642259626, 8.986779991402123);
        // load finished
        map = new window.google.maps.Map(mapRef.current, {
            center: address_latlng,
            zoom: 21,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: GoogleMapsStyle
        });

        var icon = {
            url: '../../assets/img/demo/marker.png',
            size: new window.google.maps.Size(112, 118)
        };

        map.setCenter(address_latlng);

        // new window.google.maps.Marker({
        //     position: address_latlng,
        //     map: map,
        //     icon: icon
        // });
    }
    document.body.classList.add('page');

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Contatti | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio parrucchiere, taglio, shampoo, piega, prodotti, bellezza, cura nel cuore di Mendrisio. Scopri i nostri trattamenti di bellezza e cura per un look impeccabile." />
                <meta name="keywords" content="Parrucchiere, Mendrisio, Parrucchiere Mendrisio, Donna, Uomo, Taglio, Capelli, Shampoo, Piega, Trattamenti, Cura, Bellezza, Prodotti" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleContacts />
                <div className="description mt-3 text-center">
                    <p className='mb-0'>Per ulteriori informazioni o per una</p>
                    <p className='mb-0'>consulenza gratuita in salone non esitate</p>
                    <p className='mb-0'>a contattarci.</p>
                </div>
                <div id="page-content" className="spacer p-4">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                {/* <GoogleMaps /> */}
                                <div className="spacer pt-3 text-center">
                                    <div className="title text-center">
                                        <h2 style={{ fontSize: '2rem' }}>Salone Borgo d'Oro</h2>
                                    </div>

                                    <div className="text-center row row-gutter with-pb-xl spacer p-top-lg justify-content-center">
                                        <div className="col-12">
                                            <ContactsInside />
                                        </div>
                                    </div>
                                </div>
                                <div id="google-maps" className='mt-5' ref={mapRef}></div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
