import BackToServices from "../../components/button/BackToServices";

const SpecificTreatments = () => {
    return (<div className="wrapper">
        <div className="content">
            <div id="single">
                <div id="single-content">
                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="/assets/img/bg/treatmnet.jpg" alt="Trattamenti Specifici" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                        <div className="description">
                            <p>Uno dei punti centrali del nostro salone è la salute e la cura dei capelli, e per questo utilizziamo solamente prodotti di qualità e offriamo trattamenti specifici per migliorare la salute e prendersi cura della struttura del capello. La laminazione crea un film protettivo attorno ai capelli, conferendo lucentezza e morbidezza, rendendoli più gestibili e riducendo l'effetto crespo.</p>
                            <p>Questo trattamento è ideale per chi desidera capelli lisci e setosi. Nel trattamento alla cheratina, la cheratina, una proteina naturalmente presente nei capelli, viene applicata e penetra in profondità per eliminare il crespo, lisciare il capello, ridurre il volume e donare una chioma più morbida, lucida e disciplinata.</p>
                            <p>Per la ricostruzione del capello offriamo due trattamenti distinti e altamente efficaci: il trattamento Gold20 e la ricostruzione naturale con sali minerali di Jurvedha. Questa doppia scelta ci consente di personalizzare l'intervento in base alle esigenze specifiche di ogni tipo di capello, poiché non esiste una soluzione unica adatta a tutti. Dopo una consulenza approfondita, la nostra esperta selezionerà il trattamento più indicato per garantire risultati ottimali e duraturi.</p>
                            <p> Per un trattamento di nutrimento, l'impacco di bava di lumaca è l'opzione più adatta. Le sue proprietà benefiche derivano dalla sua composizione chimica unica, ricca di mucopolisaccaridi, acido glicolico, collagene, elastina, allantoina, vitamine (A, C, E) e minerali. Questi componenti naturali agiscono in sinergia per migliorare la salute e la struttura dei capelli, idratando e nutrendo i capelli in profondità, donando elasticità e morbidezza e migliorando l'aspetto di capelli sfibrati e spenti</p>
                        </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                        <div className="title">
                            <h2 className="hr">Listino prezzi</h2>
                        </div>

                        <div className="price-list">
                            <div className="price-list-items">
                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Trattamenti Specifici</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Laminazione</span>
                                            <span className="list-group-price">da 80 a 100 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Trattamento Cheratina</span>
                                            <span className="list-group-price">da 150 a 250 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Trattamento Ricostruzione Gold20</span>
                                            <span className="list-group-price">da 20 a 40 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Ricostruzione naturale con sali minerali Jurvedha</span>
                                            <span className="list-group-price">20 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Impacco alla Bava di Lumaca</span>
                                            <span className="list-group-price">10 CHF</span>
                                        </li>

                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="title">
                                        <h6>Sconto Studenti: 20%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BackToServices />
                </div>
            </div>
        </div>
    </div>
    )
}

export default SpecificTreatments;