import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from '../blocks/services/ServicesHome';
import AboutsUs from '../blocks/about/AboutUs';
import News from '../blocks/news/News';
import Newsletter from '../blocks/newsletter/Newsletter';


const Home = () => {
    document.body.classList.add('home');
    document.body.classList.add('header-absolute-true');

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Home | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio parrucchiere, taglio, shampoo, piega, prodotti, bellezza, cura nel cuore di Mendrisio. Scopri i nostri trattamenti di bellezza e cura per un look impeccabile." />
                <meta name="keywords" content="Parrucchiere, Mendrisio, Parrucchiere Mendrisio, Donna, Uomo, Taglio, Capelli, Shampoo, Piega, Trattamenti, Cura, Bellezza, Prodotti" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />
                        <AboutsUs />
                        <div id="img" className="block spacer p-top-xl">
                        </div>
                        <ServicesHome />
                        <div id="img" className="block spacer p-top-xl">
                            <div className="img-no-wrap-1 img-not-gray">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img style={{ cursor: 'pointer',objectFit:'cover' }} onClick={() => window.location.href = '/gallery'} src="/assets/img/bg/bg-2.jpg" alt="Beauty Salon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ContactsUs />
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
