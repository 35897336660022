import React, { Component, Fragment } from 'react';
import SwipeBox from 'fslightbox-react';

class SwipeBoxComponent extends Component {
    state = {
        isVisible: false,
        slide: 0,
    };
    showSlide = (slide, event) => {
        event.preventDefault();
        this.setState({
            isVisible: !this.state.isVisible,
            slide: slide
        });
    };

    render() {
        return (
            <Fragment>
                <SwipeBox
                    toggler={this.state.isVisible}
                    slide={this.state.slide}
                    sources={[
                        '/assets/img/1.jpg',
                        '/assets/img/2.jpg',
                        '/assets/img/3.jpg',
                        '/assets/img/4.jpg',
                        '/assets/img/5.jpg',
                        '/assets/img/6.jpg',
                        '/assets/img/7.jpg',
                        '/assets/img/8.jpg',
                        '/assets/img/9.jpg',
                        // "/assets/video/1.mp4",
                        // "/assets/video/2.mp4"
                    ]}
                />

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(1, event)} title="Blonde 1" ref={this.swipeImg} className="gallery-item swipebox" href="/assets/img/1.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/1.jpg" alt="Blonde 1" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(2, event)} title="Blonde 2" className="gallery-item swipebox" href="/assets/img/2.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/2.jpg" alt="Blonde 1" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(3, event)} title="Blonde 3" className="gallery-item swipebox" href="/assets/img/3.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/3.jpg" alt="Blonde 3" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(3, event)} title="Blonde 4" className="gallery-item swipebox" href="/assets/img/4.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/4.jpg" alt="Blonde 4" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(4, event)} title="Brown 1" className="gallery-item swipebox" href="/assets/img/5.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/5.jpg" alt="Brown 1" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(4, event)} title="Brown 2" className="gallery-item swipebox" href="/assets/img/6.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/6.jpg" alt="Brown 2" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>


                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(4, event)} title="Black 1" className="gallery-item swipebox" href="/assets/img/7.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/7.jpg" alt="Black 1" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(4, event)} title="Pink 1" className="gallery-item swipebox" href="/assets/img/8.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/8.jpg" alt="Pink 1" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(4, event)} title="Blonde 5" className="gallery-item swipebox" href="/assets/img/9.jpg" rel="gallery">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="/assets/img/9.jpg" alt="Blonde 5" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a>
                </div>

                {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(9, event)} title="Video 1" className="gallery-item swipebox" href="/assets/video/1.mp4" rel="gallery">
                        <div className="video-thumbnail">
                            <video width="100%" controls>
                                <source src="/assets/video/1.mp4" type="video/mp4" />
                                Il tuo browser non supporta il tag video.
                            </video>
                        </div>
                        <div className="img-bg-color"></div>
                    </a>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <a onClick={(event) => this.showSlide(10, event)} title="Video 2" className="gallery-item swipebox" href="/assets/video/2.mp4" rel="gallery">
                        <div className="video-thumbnail">
                            <video width="100%" controls>
                                <source src="/assets/video/2.mp4" type="video/mp4" />
                                Il tuo browser non supporta il tag video.
                            </video>
                        </div>
                        <div className="img-bg-color"></div>
                    </a>
                </div> */}
            </Fragment>
        );
    }
}

export default SwipeBoxComponent;
