import BackToServices from "../../components/button/BackToServices";

const Colors = () => {
    return (<div className="wrapper">
        <div className="content">
            <div id="single">
                <div id="single-content">
                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="/assets/img/bg/color.jpg" alt="Colorazioni" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                        <div className="description">
                            <p>Il servizio di colorazione dei capelli offre un'opportunità unica per esprimere la propria personalità e stile. Grazie a una vasta gamma di tonalità, dalle nuance naturali a quelle più audaci, ogni cliente può trovare la soluzione perfetta per il proprio look.</p>
                            <p>Le colorazioni sono realizzate con prodotti di alta qualità, che garantiscono una copertura uniforme e una durata prolungata. Dalla colorazione permanente, ideale per chi cerca un cambiamento radicale e duraturo, alla colorazione semi-permanente, perfetta per chi desidera un tocco di colore temporaneo, ogni trattamento è personalizzato in base alle esigenze individuali. In aggiunta, le tecniche di balayage e meches permettono di ottenere effetti di luce e profondità, donando dimensione e movimento ai capelli. Lasciati guidare dai nostri parrucchieri esperti nella scelta del colore perfetto.</p>
                            <p>Dopo una consulenza personalizzata, ti proporranno la tonalità ideale per esaltare la tua bellezza naturale e soddisfare pienamente i tuoi desideri.</p>
                        </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                        <div className="title">
                            <h2 className="hr">Listino prezzi</h2>
                        </div>

                        <div className="price-list">
                            <div className="price-list-items">
                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Colorazioni</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Mèches Parziali</span>
                                            <span className="list-group-price">da 35 a 55 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Mèches Complete</span>
                                            <span className="list-group-price">da 60 a 90  CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Colore + Piega</span>
                                            <span className="list-group-price">da 108 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Colore + Taglio + Piega</span>
                                            <span className="list-group-price">da 123 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Tonalizzante</span>
                                            <span className="list-group-price">20 CHF</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="title">
                                        <h6>Sconto Studenti: 20%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BackToServices />
                </div>
            </div>
        </div>
    </div>
    )
}

export default Colors;