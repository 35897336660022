import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import Page404 from './pages/404';
import ServicesInside from './pages/ServicesInside';

function App() {
    // useEffect(() => {
    //     document.addEventListener('touchmove', function (event) {
    //         if (event.scale !== 1) {
    //             event.preventDefault();  // Blocca lo zoom pizzicando
    //         }
    //     }, { passive: false });
    // },[])
    console.log(process.env.PUBLIC_URL)
    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/"}`}
                    element={<Home />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/about-us"}`}
                    element={<About />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/contacts"}`}
                    element={<Contacts />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/gallery"}`}
                    element={<Gallery />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services"}`}
                    element={<Services />}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside/:serviceType"}`}
                    element={<ServicesInside />}
                />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Router>
    );
}

export default App;
