import React from 'react';

const HeaderLogo = ({ logoColor }) => {
    return (
        <div className="header-logo">
            <a className="logo logo-secondary transform-scale-h" title="Logo" href={process.env.PUBLIC_URL + "/"}>
                <a href={process.env.PUBLIC_URL + "/"} className="btn btn-lg border-0 p-0 min-w-auto"><img src={"/assets/img/logo/logo.png"} alt="Logo" className='logo-width' /></a>
            </a>
        </div>
    );
};

export default HeaderLogo;
