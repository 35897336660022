import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle404 from '../blocks/page-title/PageTitle404';
import BackToHome from '../components/button/BackToHome';
import SearchForm from '../components/form/SearchForm';

const Page404 = () => {
    document.body.classList.add( 'error404' );

    return (
        <Fragment>
           <Helmet>
                <meta charSet="UTF-8" />
                <title>404 | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio parrucchiere, taglio, shampoo, piega, prodotti, bellezza, cura nel cuore di Mendrisio. Scopri i nostri trattamenti di bellezza e cura per un look impeccabile." />
                <meta name="keywords" content="Parrucchiere, Mendrisio, Parrucchiere Mendrisio, Donna, Uomo, Taglio, Capelli, Shampoo, Piega, Trattamenti, Cura, Bellezza, Prodotti" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitle404 />

                <div id="page-content" className="spacer p-top-lg">
                    <div className="wrapper">
                        <div className="content mb-3">
                            <p className="text after">La pagina che stavi cercando non può essere trovata. La pagina potrebbe essere stata rimossa oppure hai digitato male la parola mentre la cercavi.</p>
                            <BackToHome />
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Page404;
