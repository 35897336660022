import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import { Navigate, useParams } from '../../node_modules/react-router-dom/dist/index';
import Man from '../blocks/services-inside/Man';
import Woman from '../blocks/services-inside/Woman';
import Colors from '../blocks/services-inside/Colors';
import ScalpTreatments from '../blocks/services-inside/ScalpTreatments';
import SpecificTreatments from '../blocks/services-inside/SpecificTreatments';
import DeepshotTreaments from '../blocks/services-inside/DeepshotTreatments';

const ServicesInside = () => {
    const { serviceType } = useParams();

    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');

    const title = () => {
        switch (serviceType) {
            case "man":
                return "Uomo";
            case "woman":
                return "Donna";
            case "colors":
                return "Colorazione";
            case "scalp-treatments":
                return "Trattementi del Cuoio Capelluto";
            case "specific-treatments":
                return "Trattamenti specifici";
            case "deepshot-treatments":
                return "Trattamento Anticaduta DeepShoot";
            default:
                return "";
        }
    }

    if (!["man", "woman", "colors", "scalp-treatments", "specific-treatments", "deepshot-treatments"].includes(serviceType)) {
        // Redirige alla pagina 404 se il serviceType non corrisponde a nessun caso
        return <Navigate to="/404" replace />;
    }
    
    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Dettaglio Servizi | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio parrucchiere, taglio, shampoo, piega, prodotti, bellezza, cura nel cuore di Mendrisio. Scopri i nostri trattamenti di bellezza e cura per un look impeccabile." />
                <meta name="keywords" content="Parrucchiere, Mendrisio, Parrucchiere Mendrisio, Donna, Taglio, Capelli, Shampoo, Piega, Trattamenti, Cura, Bellezza, Prodotti" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <div id="page-title">
                    <div className="wrapper text-center">
                        <h1 className="large">{title()}</h1>
                    </div>
                </div>

                {serviceType === 'man' && (<div id="page-content" className="spacer p-top-xl">
                    <Man/>
                </div>
                )}

                {serviceType === 'woman' && (<div id="page-content" className="spacer p-top-xl">
                    <Woman/>
                </div>
                )}

                {serviceType === 'colors' && (<div id="page-content" className="spacer p-top-xl">
                   <Colors/>
                </div>
                )}

                {serviceType === 'scalp-treatments' && (<div id="page-content" className="spacer p-top-xl">
                    <ScalpTreatments/>
                </div>
                )}

                {serviceType === 'specific-treatments' && (<div id="page-content" className="spacer p-top-xl">
                   <SpecificTreatments/>
                </div>
                )}

                {serviceType === 'deepshot-treatments' && (<div id="page-content" className="spacer p-top-xl">
                   <DeepshotTreaments/>
                </div>
                )}
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside;
