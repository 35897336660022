import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleServices from '../blocks/page-title/PageTitleServices';
import ServicesContent from "../blocks/services/ServicesContent";

const Services = () => {
    document.body.classList.add( 'archive' );

    return (
        <Fragment>
           <Helmet>
                <meta charSet="UTF-8" />
                <title>Servizi | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio parrucchiere, taglio, shampoo, piega, prodotti, bellezza, cura nel cuore di Mendrisio. Scopri i nostri trattamenti di bellezza e cura per un look impeccabile." />
                <meta name="keywords" content="Parrucchiere, Mendrisio, Parrucchiere Mendrisio, Donna, Uomo, Taglio, Capelli, Shampoo, Piega, Trattamenti, Cura, Bellezza, Prodotti" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleServices />

                <div id="page-content" className="spacer p-top-xl">
                    <ServicesContent />
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Services;
