import BackToServices from "../../components/button/BackToServices";

const Woman = () => {
    return (<div className="wrapper">
        <div className="content">
            <div id="single">
                <div id="single-content">
                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="/assets/img/bg/woman.jpg" alt="Donna" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                        <div className="description">
                            <p>Il nostro salone offre un'esperienza completa e su misura per ogni cliente, con trattamenti studiati per valorizzare al massimo ogni tipo di capello e desiderio.
                                Ogni taglio è eseguito con cura per armonizzare la forma del viso e la struttura del capello, creando un look fresco e contemporaneo. Per la rimozione delle doppie punte utilizziamo una macchinetta specializzata, che ci permette di ottenere capelli sani e curati, senza sacrificare la lunghezza</p>
                            <p>La piega, realizzata con tecniche avanzate, conferisce volume e movimento, adattandosi perfettamente alle preferenze della cliente per garantire una chioma impeccabile.
                                Per chi desidera un riccio definito e duraturo, il trattamento permanente rappresenta una soluzione elegante e di grande stile.</p>
                            <p> Grazie all'utilizzo di prodotti di alta qualità e a un approccio personalizzato, ogni cliente può vivere un'esperienza di bellezza unica, con la sicurezza che la salute dei propri capelli venga sempre messa al primo posto
                            </p>
                        </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                        <div className="title">
                            <h2 className="hr">Listino prezzi</h2>
                        </div>

                        <div className="price-list">
                            <div className="price-list-items">
                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Donna</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio</span>
                                            <span className="list-group-price">da 35 a 45 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Rimozione doppie punte con macchinetta specializzata</span>
                                            <span className="list-group-price">20 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Piega</span>
                                            <span className="list-group-price">da 35 a 45 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio + Piega</span>
                                            <span className="list-group-price">da 60 a 70 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Permanente
                                            <i style={{ display: 'block', fontSize: '16px', marginTop: '10px' }}>Classica o senza ammoniaca</i>
                                            </span>
                                            <span className="list-group-price">75 CHF</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Bambina</h3>
                                    </div>

                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio (fino a 11 anni)</span>
                                            <span className="list-group-price">15 CHF</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Taglio (12-17 anni)</span>
                                            <span className="list-group-price">25 CHF</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="title">
                                        <h6>Sconto Studenti: 20%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BackToServices />
                </div>
            </div>
        </div>
    </div>
    )
}

export default Woman;