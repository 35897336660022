import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
            <p>© {new Date().getFullYear()} <u><a href='https://www.iubenda.com/privacy-policy/80086592' target='_blank'>Privacy Policy</a></u> | <u><a href='https://www.iubenda.com/privacy-policy/80086592/cookie-policy' target='_blank'>Cookie Policy</a></u> | Made with ❤️ <a className='fabio-link-dark' href='https://fabiolaganga.com' target='_blank'>Fabio</a></p>
        </div>
    );
};

export default Copyright;
