import React, { Component } from 'react';
import Swiper from 'swiper';

class ServicesHome extends Component {
    componentDidMount() {
        new Swiper('.adv-slider-services .adv-swiper-container', {
            // ADV swiper
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            navigation: {
                disabledClass: 'adv-swiper-button-disabled',
                hiddenClass: 'adv-swiper-button-hidden',
                lockClass: 'adv-swiper-button-lock',
                nextEl: '.adv-2-swiper-button-next',
                prevEl: '.adv-2-swiper-button-prev'
            },
            spaceBetween: 0,
            slidesPerView: 'auto'
        });
    }

    render() {
        return (
            <section id="services" className="block">
                <div className="adv-slider-services">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper services-items clearfix">
                            <a title="Uomo" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside/man"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Uomo</h3>

                                    <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto link-no-space">Più Informazioni</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img className='not-gray' src="/assets/img/bg/man.jpg" alt="Uomo" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Donna" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside/woman"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Donna</h3>

                                    <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto link-no-space">Più Informazioni</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img className='not-gray' src="/assets/img/bg/woman.jpg" alt="Donna" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Colorazioni" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside/colors"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Colorazioni</h3>

                                    <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto link-no-space">Più Informazioni</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img className='not-gray' src="/assets/img/bg/color.jpg" alt="Colorazioni" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>
                        </div>

                        <div className="adv-2-swiper-button-prev">
                            <span>
                                <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto">←</span>
                            </span>
                        </div>

                        <div className="adv-2-swiper-button-next">
                            <span>
                                <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto">→</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div id="img" className="block spacer p-1"></div>

                <div className="adv-slider-services">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper services-items clearfix">

                            <a title="Trattamenti specifici" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside/specific-treatments"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Trattamenti specifici</h3>

                                    <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto link-no-space">Più Informazioni</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img className='not-gray' src="/assets/img/bg/treatmnet.jpg" alt="Trattamenti specifici" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Trattamenti del Cuoio Capelluto" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside/scalp-treatments"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Trattamenti del Cuoio Capelluto</h3>

                                    <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto link-no-space">Più Informazioni</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img className='not-gray' src="/assets/img/bg/hair-treatment.jpg" alt="Trattamenti del Cuoio Capelluto" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Trattamento Anticaduta DeepShoot" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside/deepshot-treatments"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Trattamento Anticaduta DeepShoot</h3>

                                    <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto link-no-space">Più Informazioni</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img className='not-gray' src="/assets/img/bg/deepshot.jpg" alt="Trattamento Anticaduta DeepShoot" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>
                        </div>

                        <div className="adv-2-swiper-button-prev">
                            <span>
                                <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto">←</span>
                            </span>
                        </div>

                        <div className="adv-2-swiper-button-next">
                            <span>
                                <span className="btn btn-lg  btn-link border-0 p-0 min-w-auto">→</span>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServicesHome;
