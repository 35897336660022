import React from 'react';
import { Link } from 'react-scroll';

const PageTitleHome = () => {
    return (
        <div style={{ height: '100vh', position: 'relative' }}>
            <section id="page-title" className="block with-img" style={{ backgroundImage: 'url(assets/img/bg/bg-1.JPEG)', backgroundSize: 'cover' }}>
                <div className="wrapper text-center d-flex">
                    <div className="align-self-center w-100">
                        <div className="title">
                            <h1 className="large">Salone Borgo d'Oro
                            </h1>
                        </div>
                        <div className="description spacer p-top-lg">
                            <p>il tuo Borgo di Bellezza</p>
                        </div>
                        <div className="spacer p-top-lg no-space">
                            <Link href="#about-us"
                                to="about-us"
                                spy={true}
                                smooth={true}
                                className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id"
                                style={{ bottom: '40px' }}>
                                <i className="fa fa-arrow-down" style={{ fontSize: '2rem' }}></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="page-title-bg-color"></div>
            </section>
        </div>
    );
};

export default PageTitleHome;
