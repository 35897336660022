import BackToServices from "../../components/button/BackToServices";

const ScalpTreatments = () => {
    return (<div className="wrapper">
        <div className="content">
            <div id="single">
                <div id="single-content">
                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="/assets/img/bg/hair-treatment.jpg" alt="Trattamenti capello" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                        <div className="description">
                            <p>Il nostro salone offre un trattamento mirato a curare disturbi del cuoio capelluto come prurito, forfora, dermatite, psoriasi, alopecia, e cute grassa. Al centro del trattamento ci sono gli oli essenziali naturali e puri al 100% di Jurvedha, noti per le loro proprietà purificanti e rigeneranti.</p>
                            <p>Attraverso un'applicazione curata e un massaggio mirato, gli oli essenziali penetrano in profondità e riequilibrano il cuoio capelluto, promuovendo la sua salute. Il trattamento inizia con un'analisi tramite microcamera, grazie alla quale la nostra esperta può identificare l'anomalia presente. In base al problema rilevato, verrà selezionato l'olio essenziale più adatto e consigliato il percorso di trattamento ideale.</p>
                        </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                        <div className="title">
                            <h2 className="hr">Listino prezzi</h2>
                        </div>

                        <div className="price-list">
                            <div className="price-list-items">
                                <div className="price-list-item">
                                    <div className="price-list-item-title">
                                        <h3>Trattementi del Cuoio Capelluto</h3>
                                    </div>
                                    <ul className="price-list-item-list-group list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="list-group-title">Analisi Microcamera</span>
                                            <span className="list-group-price">GRATUITA</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center" style={{ border: 'none' }}>
                                            <span className="list-group-title">Trattamento con Oli Essenziali Jurvedha</span>
                                            <span className="list-group-price">35 CHF</span>
                                        </li>
                                        <li className="list-group-item" style={{ border: 'none', padding: '0px 0px 0px 35px', marginBottom: '40px!important' }}>Cute Grassa</li>
                                        <li className="list-group-item" style={{ border: 'none', padding: '0px 0px 0px 35px', }}>Forfora</li>
                                        <li className="list-group-item" style={{ border: 'none', padding: '0px 0px 0px 35px', }}>Caduta Capelli</li>
                                        <li className="list-group-item" style={{ border: 'none', padding: '0px 0px 0px 35px', }}>Dermatite</li>
                                        <li className="list-group-item" style={{ padding: '0px 0px 40px 35px', }}>Psoriasi</li>
                                    </ul>
                                </div>

                                <div className="price-list-item">
                                    <div className="title">
                                        <h6>Sconto Studenti: 20%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BackToServices />
                </div>
            </div>
        </div>
    </div>
    )
}

export default ScalpTreatments;